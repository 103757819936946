<template>
  <div id="form-crud-user">

    <h3 class="mb-3">Informations globales</h3>
    <hr />

    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      <form @submit.prevent="initSubmit">
        <div class="col-12 col-md-6">

          <div class="form-group" v-if="$store.getters.userIsAdmin && from === 'crud'">
            <label for="username">Nom de l'utilisateur</label>
            <input class="form-control" id="username" v-model="user.username" type="text">
            <div v-if="errorsForm.username">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.username.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input class="form-control" id="email" v-model="user.email" type="email">
            <div v-if="errorsForm.email">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.email.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div v-if="actionForm === 'edit'" class="form-group">
            <label for="password">Mot de passe</label>
            <input class="form-control" id="password" v-model="form.password" type="password">
            <div v-if="errorsForm.password">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.password.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group" v-if="$store.getters.userIsAdmin">
            <label for="select-roles">Role</label>
            <select id="select-roles" class="form-control" v-model="user.roles">
              <option v-for="(role, index) in UserModule.roles" :key="index" :value="role.index">
                {{ role.name }}
              </option>
            </select>
            <div v-if="errorsForm.roles">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.roles.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="firstname">Prénom</label>
            <input class="form-control" id="firstname" v-model="user.firstname" type="text">
            <div v-if="errorsForm.firstname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.firstname.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="lastname">Nom</label>
            <input class="form-control" id="lastname" v-model="user.lastname" type="text">
            <div v-if="errorsForm.lastname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.lastname.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="phone">Téléphone</label>
            <input class="form-control" id="phone" v-model="user.phone" type="text">
            <div v-if="errorsForm.phone">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.phone.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <h3 v-if="$store.getters.userIsAdmin && (user.roles) ? user.roles[0] === 'ROLE_SHOP' : ''" class="mt-4 mb-4">
          Affecter l'utilisateur à un commerce
        </h3>
        <hr v-if="$store.getters.userIsAdmin && (user.roles) ? user.roles[0] === 'ROLE_SHOP' : ''" />

        <div v-if="$store.getters.userIsAdmin && (user.roles) ? user.roles[0] === 'ROLE_SHOP' : ''"  class="col-12 col-md-6">
          <div class="form-group">
            <label>Commerce</label>
            <select class="form-control" v-model="selectShop" @input="changeShop()">
              <option value="null">Pas de commerce</option>
              <option v-for="(shop, index) in ShopModule.shops" :key="index" :value="shop.id">
                {{ shop.name }}
              </option>
            </select>

            <div v-if="errorsForm.shop">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.shop.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <h3 v-if="$store.getters.userIsAdmin && from === 'crud'"  class="mt-4 mb-4">Config</h3>
        <hr v-if="$store.getters.userIsAdmin && from === 'crud'" />

        <div v-if="$store.getters.userIsAdmin && from === 'crud'"  class="col-6">
          <div class="form-group">
            <label>Activer l'utilisateur ?</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-active-yes" v-model="user.isActive" :checked="user.isActive === true"  v-bind:value="true">
              <label class="form-check-label" for="is-active-yes">
                Oui
              </label>
            </div>
            <div class="form-check" >
              <input class="form-check-input" type="radio" id="is-active-no" v-model="user.isActive" :checked="user.isActive === false" v-bind:value="false">
              <label class="form-check-label" for="is-active-no">
                non
              </label>
            </div>
          </div>
        </div>

        <input type="submit" class="btn mt-4 ml-3 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    user: Object,
    id: Number,
    actionForm: String,
    selectedShop: Number,
    from: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      errorsForm: [],
      selectShop: this.selectedShop,
      form: {
        password: null
      }
    }
  },
  watch: {
    selectedShop: function() {
      this.selectShop = this.selectedShop
    }
  },
  created: function() {
    this.$store.dispatch('fetchShops', {
      centerId: this.$store.getters.getChannel.center.id,
      perPage: 0,
      page: 0
    })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    changeShop: function(){
      this.$emit('input', this.selectShop);
    },
    initSubmit()
    {
      // Handle shop
      if(this.from !== 'profile') {
        this.user.shop = this.selectShop
      }

      // If user (create or update)
      if(this.actionForm === 'edit') {
        this.editUser()
      } else {
        this.createUser()
      }
    },
    /**
     * Create user (submit form)
     */
    createUser() {

      this.showLoader()

      // Handle shop By Roles
      if(this.user.roles) {
        if(this.user.roles[0] !== 'ROLE_SHOP') {
          this.user.shop = null
        }
      }

      let model = {
        centerId: this.$store.getters.getChannel.center.id,
        user: this.user
      }

      this.$store.dispatch('createUser', model)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update user (submit form)
     */
    editUser() {
      this.showLoader()

      // Set value form password into user in state
      this.user.password = this.form.password
      // Handle shop By Roles
      if(this.user.roles) {
        if(this.user.roles[0] !== 'ROLE_SHOP') {
          this.user.shop = null
        }
      }

      let model = {
        centerId: this.$store.getters.getChannel.center.id,
        user: this.user
      }

      this.$store.dispatch('updateUser', model)
          .then(() => {
            this.hideLoader()

            if(this.from === 'profile') {
              this.$bvToast.toast('Vos informations de compte on été mise à jour.', {
                title: 'Edition de votre compte',
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'success',
              })
            }

            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to user-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      if(this.$store.getters.userIsAdmin) {
        if(this.from === 'crud') {
          this.$router.push({ name: 'user-list' })
        } else {
          // Same route
          this.$router.push({ query: { type: 2 } })
        }
      }

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit') {
        value = (this.$store.getters.userIsAdmin) ? 'Enregistrer les modifications' : 'Enregistrer les modifications'
      } else {
        value = 'Créer un utilisateur'
      }

      return value
    },
    ...mapState(['ChannelModule']),
    ...mapState(['ShopModule']),
    ...mapState(['UserModule'])
  },
  components: {
  }
}
</script>

<style scoped>

</style>
